<style scoped>
.table {
  font-size: 0.7em;
}
.font-smaller {
  font-size: 0.8em;
}
</style>

<template>
  <div class="mt-3">
    <!-- Modal component to Edit User Info -->
    <user-info
      v-bind:user="editUser"
      v-bind:update-root-user="false"
      v-on:user-edited="getListResidents()"
    />
    <h4>Settings</h4>

    <!-- List of Users -->
    <h4 class="text-left mt-5 mb-2">Residents</h4>
    <b-table
      class="table mt-3"
      ref="residents"
      striped
      small
      hover
      :items="listResidents"
      :fields="fields"
      :key="listResidents.id"
      sort-by="Status"
      :sort-desc="true"
      selectable
      select-mode="single"
      v-on:row-selected="rowSelected($event)"
    >
      <template v-slot:cell(Provider)="data">{{ data.value ? "Yes":"No" }}</template>
      <template v-slot:cell(Status)="data">{{ role(data.value) }}</template>
    </b-table>
    <div class="text-right">
      <b-button variant="primary" @click="addUser()">Add Resident</b-button>
    </div>

    <b-form class="mt-5">
      <!-- Options for Meals -->
      <h4 class="text-left">What meals do you want displayed?</h4>
      <div class="text-left">
        <b-form-group label="Breakfast:">
          <b-form-checkbox-group
            size="sm"
            id="checkbox-group-1"
            v-model="breakfast"
            :options="options"
            name="Breakfast"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group label="Lunch:">
          <b-form-checkbox-group
            size="sm"
            id="checkbox-group-2"
            v-model="lunch"
            :options="options"
            name="Lunch"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group label="Dinner:">
          <b-form-checkbox-group
            size="sm"
            id="checkbox-group-3"
            v-model="dinner"
            :options="options"
            name="Dinner"
          ></b-form-checkbox-group>
        </b-form-group>
        <div>
          <b-row no-gutters>
            <b-col cols="auto">X: In, O: Out, L: Late,</b-col>
            <b-col cols="auto">
              <b-input-group size="sm" prepend="E " class="ml-2">
                <b-form-select v-model="settings.labelE" size="sm" class="w-auto text-left">
                  <option value="Early">Early</option>
                  <option value="Extra">Extra</option>
                </b-form-select>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- StartWeekDay and Reminders-->
      <h4 class="text-left mt-5 mb-2">Week & Reminders Setup</h4>
      <div class="text-left mt-3">
        <div>
          <b-input-group size="sm" prepend="Week starts on ">
            <b-form-select
              v-model="settings.startWeekDay"
              :options="optionsStartDayWeekSelector"
              size="sm"
              class="w-auto text-left"
            ></b-form-select>
          </b-input-group>
        </div>

        <div class="mt-2">
          <b-input-group size="sm" prepend="Reminder on " append=" before week starts">
            <b-form-select
              v-model="settings.firstDayReminder"
              :options="optionsStartDayWeekSelector"
              size="sm"
              class="w-auto text-left"
            ></b-form-select>
          </b-input-group>
        </div>

        <div class="text-muted font-smaller">
          A text reminder is sent to any resident who has not submitted a week’s
          meal count by the deadline you set at 7:30am and 5:00 pm on weekdays and
          10:00 am and 1 pm on weekends.
          <br />Note: text reminders will continue
          to be sent daily until he is “caught up.”
        </div>
      </div>

      <!-- Info from MyNetCalendarDB -->
      <div class="text-left mt-5">
        <b-button v-b-toggle.collapse-1 variant="info">Advance settings</b-button>

        <b-collapse id="collapse-1" class="mt-2">
          <h4
            class="text-left mb-2"
          >What activities do you want displayed in the Calendar and other views?</h4>

          <b-tabs pills content-class="mt-3 text-left">
            <b-tab title="To Residents" active>
              <b-form-group label>
                <b-form-checkbox-group
                  style="flex-wrap: wrap;"
                  v-model="settingsActRes"
                  :options="settings.acts"
                  name="buttons-1"
                  size="sm"
                  inline
                ></b-form-checkbox-group>
              </b-form-group>
            </b-tab>
            <b-tab title="To Admin">
              <b-form-group label>
                <b-form-checkbox-group
                  style="flex-wrap: wrap;"
                  v-model="settingsActAdmin"
                  :options="settings.acts"
                  name="buttons-1"
                  size="sm"
                  inline
                ></b-form-checkbox-group>
              </b-form-group>
            </b-tab>
            <b-tab title="In Day View">
              <b-form-group label>
                <b-form-checkbox-group
                  style="flex-wrap: wrap;"
                  v-model="settingsActDayView"
                  :options="settings.acts"
                  name="buttons-1"
                  size="sm"
                  inline
                ></b-form-checkbox-group>
              </b-form-group>
            </b-tab>
          </b-tabs>
        </b-collapse>
      </div>

      <b-button class="mt-5 mb-3" @click="onSave()" variant="primary">Save</b-button>
    </b-form>
  </div>
</template>

<script>
import UserInfo from "@/components/UserInfo.vue";

export default {
  data() {
    return {
      listResidents: [],
      fields: [
        { key: "Name", label: "Name", sortable: true },
        { key: "Diet", label: "Diet", sortable: true },
        { key: "Provider", label: "Reminders", sortable: true },
        { key: "Status", label: "Role", sortable: true }
      ],
      editUser: {
        id: 0,
        name: "",
        initials: "",
        password: "",
        diet: "",
        email: "",
        birthday: "",
        phone: "",
        provider: "",
        status: 0
      },
      options: [
        { text: "X", value: "A" },
        { text: "O", value: "B" },
        { text: "L", value: "C" },
        { text: "P", value: "D" },
        { text: "E", value: "E" }
      ],
      breakfast: [],
      lunch: [],
      dinner: [],
      settings: [],
      optionsStartDayWeekSelector: [
        { value: 0, text: "Sunday" },
        { value: 1, text: "Monday" },
        { value: 2, text: "Tuesday" },
        { value: 3, text: "Wednesday" },
        { value: 4, text: "Thursday" },
        { value: 5, text: "Friday" },
        { value: 6, text: "Saturday" }
      ],
      settingsActRes: [],
      settingsActAdmin: [],
      settingsActDayView: []
    };
  },
  computed: {
    breakfastArray() {
      var a = [];
      var obj = { type: "", color: "" };
      var color = "default";
      var type = "";
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.breakfast.sort();
      // eslint-disable-next-line no-unused-vars
      this.breakfast.forEach(function(value, index) {
        switch (value) {
          case "A":
            color = "success";
            type = "X";
            break;
          case "B":
            color = "danger";
            type = "O";
            break;
          case "C":
            color = "warning";
            type = "L";
            break;
          case "D":
            color = "warning";
            type = "P";
            break;
          case "E":
            color = "warning";
            type = "E";
            break;
          default:
            color = "success";
            type = "X";
            break;
        }
        obj = { type: type, color: color };
        a.push(obj);
      });
      return a;
    },
    lunchArray() {
      var a = [];
      var obj = { type: "", color: "" };
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.lunch.sort();
      this.lunch.forEach(function(value) {
        obj = { type: "", color: "" };
        switch (value) {
          case "A":
            obj.color = "success";
            obj.type = "X";
            break;
          case "B":
            obj.color = "danger";
            obj.type = "O";
            break;
          case "C":
            obj.color = "warning";
            obj.type = "L";
            break;
          case "D":
            obj.color = "warning";
            obj.type = "P";
            break;
          case "E":
            obj.color = "warning";
            obj.type = "E";
            break;
          default:
            obj.color = "success";
            obj.type = "X";
            break;
        }

        a.push(obj);
      });
      return a;
    },
    dinnerArray() {
      var a = [];
      var obj = { type: "", color: "" };
      this.dinner.forEach(function(value) {
        obj = { type: "", color: "" };
        switch (value) {
          case "A":
            obj.color = "success";
            obj.type = "X";
            break;
          case "B":
            obj.color = "danger";
            obj.type = "O";
            break;
          case "C":
            obj.color = "warning";
            obj.type = "L";
            break;
          case "D":
            obj.color = "warning";
            obj.type = "P";
            break;
          case "E":
            obj.color = "warning";
            obj.type = "E";
            break;
          default:
            obj.color = "success";
            obj.type = "X";
            break;
        }
        a.push(obj);
      });
      return a;
    }
  },
  mounted() {
    this.getListResidents();
    this.getSettings();
  },
  components: {
    UserInfo
  },
  methods: {
    getListResidents() {
      var url = "getListResidents.php";
      var data = {};
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.listResidents = res.body;
        },
        err => {
          console.log(err);
        }
      );
    },
    getSettings() {
      var url = "getSettings.php";
      var data = {};
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.settings = res.body.meals;
          this.settings.acts = [...res.body.Act];
          this.settingsActRes = JSON.parse(
            ("[" + this.settings.db_actsCalResidents + "]").replace(/'/g, '"')
          );
          this.settingsActAdmin = JSON.parse(
            ("[" + this.settings.db_actsCalAdmin + "]").replace(/'/g, '"')
          );
          this.settingsActDayView = JSON.parse(
            ("[" + this.settings.db_actsDayIncluded + "]").replace(/'/g, '"')
          );
          this.settings.firstDayReminder =
            this.settings.firstDayReminder || "3";
          this.settings.timeReminder1 = this.settings.timeReminder1 || "10:00";
          this.settings.timeReminder2 = this.settings.timeReminder2 || "13:00";

          var a = [];
          this.settings.Breakfast.forEach(function(value) {
            switch (value.type) {
              case "X":
                a.push("A");
                break;
              case "O":
                a.push("B");
                break;
              case "L":
                a.push("C");
                break;
              case "P":
                a.push("D");
                break;
              case "E":
                a.push("E");
                break;
              default:
                a.push("E");
                break;
            }
          });
          this.breakfast = a;
          var b = [];
          this.settings.Lunch.forEach(function(value) {
            switch (value.type) {
              case "X":
                b.push("A");
                break;
              case "O":
                b.push("B");
                break;
              case "L":
                b.push("C");
                break;
              case "P":
                b.push("D");
                break;
              case "E":
                b.push("E");
                break;
              default:
                b.push("E");
                break;
            }
          });
          this.lunch = b;
          var c = [];
          this.settings.Dinner.forEach(function(value) {
            switch (value.type) {
              case "X":
                c.push("A");
                break;
              case "O":
                c.push("B");
                break;
              case "L":
                c.push("C");
                break;
              case "P":
                c.push("D");
                break;
              case "E":
                c.push("E");
                break;
              default:
                c.push("E");
                break;
            }
          });
          this.dinner = c;
          this.settings.labelE = this.settings.labelE || "Early";
        },
        err => {
          console.log(err);
        }
      );
    },
    rowSelected(e) {
      if (e.length > 0) {
        var sel = e[0];
        this.$set(this.editUser, "id", sel.id);
        this.$set(this.editUser, "name", sel.Name);
        this.$set(this.editUser, "initials", sel.UserName);
        this.$set(this.editUser, "password", sel.Password);
        this.$set(this.editUser, "diet", sel.Diet);
        this.$set(this.editUser, "email", sel.Email);
        this.$set(this.editUser, "birthday", sel.Birthday);
        this.$set(this.editUser, "phone", sel.Phone);
        this.$set(this.editUser, "provider", sel.Provider);
        this.$set(this.editUser, "status", sel.Status);

        this.$bvModal.show("modal-user");
        this.$refs.residents.clearSelected();
      }
    },
    addUser() {
      this.$set(this.editUser, "id", "");
      this.$set(this.editUser, "name", "");
      this.$set(this.editUser, "initials", "");
      this.$set(this.editUser, "password", "");
      this.$set(this.editUser, "diet", "");
      this.$set(this.editUser, "email", "");
      this.$set(this.editUser, "birthday", "");
      this.$set(this.editUser, "phone", "");
      this.$set(this.editUser, "provider", "");
      this.$set(this.editUser, "status", 0);

      this.$bvModal.show("modal-user");
    },
    onSave() {
      var newSettings = {};
      newSettings = {
        Breakfast: JSON.stringify(this.breakfastArray),
        Lunch: JSON.stringify(this.lunchArray),
        Dinner: JSON.stringify(this.dinnerArray),
        db_actsCalAdmin: JSON.stringify(this.settingsActAdmin)
          .replace("[", "")
          .replace("]", ""),
        db_actsCalResidents: JSON.stringify(this.settingsActRes)
          .replace("[", "")
          .replace("]", ""),
        db_actsDayIncluded: JSON.stringify(this.settingsActDayView)
          .replace("[", "")
          .replace("]", ""),
        startWeekDay: this.settings.startWeekDay,
        firstDayReminder: this.settings.firstDayReminder,
        timeReminder1: this.settings.timeReminder1,
        timeReminder2: this.settings.timeReminder2,
        labelE: this.settings.labelE
      };

      var url = "saveSettings.php";
      var data = { settings: newSettings };
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.$store.commit("loadSettings", res.body);
          this.$bvToast.toast(`Updated`, {
            title: "Settings",
            variant: "info",
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            appendToast: false
          });
        },
        err => {
          console.log(err);
        }
      );
    },
    role(status) {
      switch (status) {
        case "1":
          return "Resident";
        case "3":
          return "Resident - Edit Notes";
        case "0":
          return "Administration";
        case "7":
          return "Priest";
        case "15":
          return "Director";

        default:
          return "";
      }
    }
  }
};
</script>
